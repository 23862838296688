<template>
  <apex-chart
    type="bar"
    :height="284"
    :options="chartOptions"
    :series="series"
  />
</template>

<script>
// Import node packages
import ApexChart from 'vue-apexcharts'
import merge from 'lodash/merge'

// Import default configuration option for chart
import apexChartBaseOptions from '@/json/apexChartBaseOptions.js'

// Export the SFC
export default {
  // Name of the component
  name: "Location",

  // Register the components
  components: {
    ApexChart
  },

  // Accept incoming data from parent
  props: {
    data: {
      type: Array,
      required: true
    },
  },

  // Readonly computable variables
  computed: {
    /**
     * Whether or not to show the location numerical values
     *
     * @returns {Boolean}
     */
     shouldHideLocationValueForTheUser() {
      return this.$store.getters["auth/profile"]?.email.includes("@x10.media")
    },

    /**
     * Get the chart series data
     *
     * @returns {Array}
     */
    series() {
      return [
        {
          data: this.data.slice(0, 5)
        }
      ]
    },

    /**
     * Get the chart configuration options
     *
     * @returns {Object}
     */
    chartOptions() {
      return merge(apexChartBaseOptions(), {
        stroke: {
          width: 5,
        },
        colors: ["#2196F3", "#ffca28"],
        dataLabels: {
          enabled: !this.shouldHideLocationValueForTheUser,
          textAnchor: "start",
          offsetY: -12,
          formatter(value) {
            return value ? numeral.fPercent(value) : "";
          },
          style: {
            colors: ["#f05d56"],
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "15%",
            borderRadius: 2,
            padding: 10,

            dataLabels: {
              enabled: true,
              position: "right",
            },
          },
        },
        yaxis: {
          type: "category",
        },
        tooltip: {
          enabled: false,
          marker: { show: false },
        },
        xaxis: {
          type: "numeric",
          labels: {
            format: "asd",
          },
          title: {
            text: "Percent",
          },
        },
      })
    }
  }
}
</script>
